import {
  isNotChinese,
  isMoney,
  numMaxConstruct,
  notNegative
} from '@/utils'
import { validatorFactory } from '@/utils/validate/validateClass'
import { charLenMaxConstructor } from '@/utils/validate'

export const column = {
  productPrototypeName: {
    label: '产品名称',
    prop: 'productPrototypeName',
    type: 'tooltip',
    placeholder: '暂无'
  },
  chineseName: {
    label: '申报中文名称',
    prop: 'chineseName',
    type: 'defaultValueInput',
    rules: [
      { required: true },
      {
        validator: charLenMaxConstructor(200)
      }
    ]
  },
  englishName: {
    label: '申报英文名称',
    prop: 'englishName',
    type: 'defaultValueInput',
    rules: [
      { required: true },
      {
        validator: charLenMaxConstructor(200)
      },
      {
        validator: isNotChinese
      }
    ]
  },
  declaredAmount: {
    label: '申报金额(USD)',
    prop: 'declaredAmount',
    type: 'defaultValueInput',
    rules: [
      { required: true },
      {
        validator: isMoney
      },
      {
        validator: numMaxConstruct(99999999.99)
      }
    ]
  },
  declaredWeight: {
    label: '申报重量(g)',
    prop: 'declaredWeight',
    type: 'defaultValueInput',
    rules: [
      { required: true },
      {
        validator: validatorFactory({ format: 'twoFixed', errMsg: '重量保留两位小数' })
      },
      {
        validator: notNegative
      }
    ]
  },
  declaredMaterial: {
    label: '材质(英文)',
    prop: 'declaredMaterial',
    type: 'defaultValueInput',
    rules: [
      { required: true },
      {
        validator: charLenMaxConstructor(100)
      },
      {
        validator: isNotChinese
      }
    ]
  },
  customsCode: {
    label: '海关编码',
    prop: 'customsCode',
    type: 'defaultValueInput',
    rules: [
      { required: true },
      {
        validator: charLenMaxConstructor(200)
      }
    ]
  },
  isSpecial: {
    label: '是否走特殊产品1',
    prop: 'isSpecial',
    type:'checkbox',
    // rules: [{ required: true }],
    dicData:[{
      label:'是否含电',
      value:'attrElectric'
    },{
      label:'是否含非液体化妆品',
      value:'attrLiquid'
    },{
      label:'是否是特货（敏感货）',
      value:'attrSpecial'
    }]
  }
}

export const formOption = {
  menuBtn: false,
  labelWidth: 130,
  span: 24,
  size: 'medium',
  column: [
    column.productPrototypeName,
    column.chineseName,
    column.englishName,
    column.declaredAmount,
    column.declaredWeight,
    column.declaredMaterial,
    column.customsCode
    // column.isSpecial
  ]
}


export const formOption1 = {
  menuBtn: false,
  labelWidth: 130,
  span: 24,
  size: 'medium',
  column: [
    column.productPrototypeName,
    column.chineseName,
    column.englishName,
    column.declaredAmount,
    column.declaredWeight,
    column.declaredMaterial,
    column.customsCode,
    column.isSpecial
  ]
}